import React, { Component } from 'react'
import ScrollUpButton from 'react-scroll-up-button';

import { connect } from 'react-redux'

import AboutUs from '../Components/AboutUs'
import Contact from '../Components/Contact'
import Currency from '../Components/Currency'
import Calculate from '../Components/Calculate'
import Banner from '../Components/Banner'
import Address from '../Components/Address'
import Centered from '../Components/Centered'

import '../Layouts/css/dashboard.css'
import ReactGA from 'react-ga';
import { withRouter } from 'react-router'


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.initializeReactGA()
  }

  initializeReactGA() {
    ReactGA.initialize('UA-135962750-1');
    ReactGA.pageview('/');
  }

  checkAuth() {

  }

  _preview = () => {

  }

  render() {

    return (

      <div className="dashboard">

        <Banner />
        <Currency />
        <Calculate />
        <AboutUs />
        <Address />
        <Contact />
        <p className="indashboard">Copyright © 2018. SIAM EXCHANGE All rights reserved.</p>

        <Centered />
        <div>
          <ScrollUpButton />
        </div>
      </div>

    )
  }
}

Dashboard.propTypes = {

}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default (connect(mapStateToProps, mapDispatchToProps)(Dashboard))
