import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Container, Row, Col } from 'reactstrap';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { PDFViewer } from '@react-pdf/renderer'
import { MdPrint } from 'react-icons/md';
import { IconContext } from "react-icons";

import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/th'
import 'moment/locale/zh-cn'

import CurrencyActions from '../Redux/CurrencyRedux'

import { strictEqual } from 'assert';

import img from '../assets/images/logo-dark.png';
import '../Layouts/css/currency.css'
import Popup from "reactjs-popup";
import Preview from './Preview'


const lang = {
  th: {
    currency: 'สกุลเงิน',
    buying: 'ราคาซื้อ',
    selling: 'ราคาขาย',
    lastupdate: 'อัพเดทเมื่อ',
    print: 'พิมพ์'
  },
  en: {
    currency: 'Currency',
    buying: 'Buying',
    selling: 'Selling',
    lastupdate: 'Last Update',
    print: 'print'
  },
  zh: {
    currency: '外币',
    buying: '买价',
    selling: '卖价',
    lastupdate: '最後更新',
    print: '打印'
  }
}

class Currency extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menu1: 'Currency',
      menu2: 'Buying',
      menu3: 'Selling',
      lastupdate: 'Last Update',
      print: 'print',
      date: '',
      time: '',
      pdfDate: '',
      open: false
    }
    this.props.getCurrency()
    // this.state = { open: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setTextByLang(this.props.lang)
  }

  componentWillReceiveProps(newProps) {
    // console.log(newProps)
    if (this.props.lang != newProps.lang) {
      this.setTextByLang(newProps.lang)
    }

    let { date, time, pdfDate } = this.calDate(newProps)
    this.setState({ date, time, pdfDate })
  }

  setTextByLang(ll) {
    this.setState({
      menu1: lang[ll].currency,
      menu2: lang[ll].buying,
      menu3: lang[ll].selling,
      lastupdate: lang[ll].lastupdate,
      print: lang[ll].print
    })
  }

  countryFormatter(cell) {
    var json = JSON.parse(cell)
    return (
      <div style={{ color: "black", fontWeight: "bold", fontSize: 20 }} dangerouslySetInnerHTML={{ __html: json.en }
      } />
    );
  }

  picturePriceFormatter(cell) {
    var res = "http://siamexchange.co.th/".concat(cell)
    return (
      <span>
        <img src={res} className="flagimg" />
      </span>
    );
  }

  currencyFormatter(cell) {
    return <div style={{ fontSize: 18, fontWeight: 'bold', color: "rgb(130, 130, 130)", }}>{cell}</div>
  }

  currencyFormatter2(cell, row) {
    var country = JSON.parse(row.country_name)
    return <div >
      <div style={{ color: "black", fontWeight: "bold" }}>{row.currency}<br /></div>
      <div style={{ fontSize: 15, color: "rgb(130, 130, 130)", fontWeight: "bold" }}>{country['en']}</div>
    </div>
  }


  sellingFormatter(cell, row) {
    let createID = 'selling'.concat(row.currency)
    return (
      <div id={createID} style={{ paddingRight: 10, height: '100%' }}>
        <span className="buyingFormatter" style={{ color: "#243470", fontWeight: "bold" }}>{cell}</span>
      </div>
    );
  }

  buyingFormatter(cell, row) {
    let createID = 'buying'.concat(row.currency)
    return (
      <div id={createID} style={{ paddingRight: 10 }}>
        <span className="buyingFormatter" style={{ color: "#243470", fontWeight: "bold" }} >{cell}</span>
      </div>
    );
  }

  countryHeaderFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
    );
  }

  priceFormatter() {
    return
  }

  calDate(props) {

    const max = props.data ? props.data.reduce(function (prev, current) {
      return (prev.updated_at > current.updated_at) ? prev : current
    }) : null
    let date = ''
    let time = ''
    let pdfDate = ''
    if (max) {
      const dt = max.updated_at.split(' ')
      date = dt[0]
      time = dt[1]
    }

    let lang = props.lang == 'zh' ? 'zh-cn' : props.lang

    moment.locale('en')
    pdfDate = moment(date).format('ll')

    moment.locale(lang)
    date = moment(date).format('ll')



    return { date, time, pdfDate }
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  render() {
    // console.log(this.props.data)
    // var update = this.props.data[0]["updated_at"]
    // var date = update.split(" ")
    // var splitDate = date[0].split("-")
    // var month = this.formatDate(splitDate[2])
    let { date, time, print, pdfDate } = this.state
    const { SearchBar } = Search;

    const columns = [
      // {
      //   dataField: 'id',
      //   text: 'Product ID'
      // },
      {
        dataField: 'icon_country',
        text: '',
        align: 'right',
        headerClasses: 'price',
        formatter: this.picturePriceFormatter,
      },
      {
        dataField: 'country_name',
        text: '',
        headerClasses: 'price-header-normal',
        formatter: this.countryFormatter,
        classes: 'colunm-table'
      },
      {
        dataField: 'currency',
        text: this.state.menu1,
        formatter: this.currencyFormatter,
        headerClasses: 'price-header-normal',
        classes: 'colunm-table-currency'
      },
      {
        dataField: 'currency',
        text: this.state.menu1,
        formatter: this.currencyFormatter2,
        headerClasses: 'price-header-medie',
        classes: 'colunm-table-medie'
      },
      {
        dataField: 'buying',
        text: this.state.menu2,
        formatter: this.buyingFormatter,
        headerClasses: 'price-header'
      },
      {
        dataField: 'selling',
        text: this.state.menu3,
        formatter: this.sellingFormatter,
        headerClasses: 'price-header'
      }

    ];


    return (
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <div className="rate_table_wrapper">
          <Container>

            <div className="row currency" id="currency">

              <ToolkitProvider
                keyField="id"
                data={this.props.data}
                columns={columns}
                search
              >
                {
                  props => (
                    <div className='tablecurrency'>

                      <Row>
                        <Col md="4">
                          <img src={img} className="logoprint" />
                          <SearchBar {...props.searchProps} />
                        </Col>
                        <Col md="8" className="header-update" style={{ textAlign: 'right' }}>
                          <span className="update">{this.state.lastupdate} : {date} / {time} {print}
                            {/* <img
                              src="http://siamexchange.co.th/images/icon-printer.svg"
                              className="img-printer"
                              alt=""
                              onClick={this.exportPDFWithComponent} /> */}
                            <MdPrint onClick={this.openModal} className="img-printer" size="2em" />
                          </span>
                          {/* <button className="button" onClick={this.openModal}>
                            Download
                     </button> */}
                          <Popup
                            contentStyle={{ width: "80%" }}
                            open={this.state.open}
                            closeOnDocumentClick
                            onClose={this.closeModal}
                          >
                            <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                              <PDFViewer style={{ width: '100%', height: 600 }}>
                                <Preview list={this.props.data} date={pdfDate} time={time} />
                              </PDFViewer>
                            </div>
                          </Popup>
                        </Col>
                      </Row>

                      <BootstrapTable
                        {...props.baseProps}
                        rowClasses={'custom-row-class'}
                        // striped
                        hover
                        bordered={false}
                        condensed

                      />
                    </div>
                  )
                }
              </ToolkitProvider>
            </div>
          </Container>
        </div>
      </IconContext.Provider>
    )
  }
  exportPDFWithComponent = () => {
    window.print();
  }

}



const mapStateToProps = (state) => ({
  country: state.currency.country,
  data: state.currency.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getCurrency: () => dispatch(CurrencyActions.requestCurrency()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Currency)

