import { call, put } from 'redux-saga/effects'
import NewsActions from '../Redux/NewsRedux'

export function* requestNews(api) {
  const response = yield call(api.getNews)

  // console.log(response)

  if (response.ok) {
    
    let news = response.data.filter(e => e.status == 1)
    let use = ''
    console.log(news.length)
    if(news.length>=1){
      if(news[news.length-1]['img']){
        use = news[news.length-1]['img']
        let url = "https://s3-ap-southeast-1.amazonaws.com/siam-exchange/popup/".concat(use)
        use = "<img style='width:100%' src="+url+" />"
        
      }else{
        use = news[news.length-1]['text']
        
      }
    }else{
        use = ''
    }
    
    
    yield put(NewsActions.requestNewsSuccess(use))
  }
}
