import { put, select } from 'redux-saga/effects'
import CalculateActions from '../Redux/CalculateRedux'

const cc = state => state.currency
const calculate = state => state.calculate

export function* changeType({ t }) {
    const ratetable = yield select(cc)
    const cal = yield select(calculate)

    let exrate = ratetable.data.filter(e => e.currency === cal.currency)
    let val = 0
    if (exrate.length > 0) {
        val = exrate[0][t]
    }

    yield put(CalculateActions.setRate(val))
    yield put(CalculateActions.typeToCal(t))
}

export function* changeCurrency({ currency }) {
    const ratetable = yield select(cc)
    const cal = yield select(calculate)

    let exrate = ratetable.data.filter(e => e.currency === currency)
    let val = 0
    if (exrate.length > 0) {
        val = exrate[0][cal.extype]
    }

    yield put(CalculateActions.setRate(val))
    yield put(CalculateActions.valueCurrency(currency))
}

export function* changeValue(value) {

}
