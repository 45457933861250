import apisauce from 'apisauce'

let b
if (process.env.NODE_ENV === 'production') {
  // b = 'http://localhost/siam-exchange-api/web/index.php'
  // b = 'https://infiltech.org/checkphra-api/web/index.php/v1/'
  b = 'http://siamexchange.co.th/api/web/index.php'
} else {
  b = 'http://siamexchange.co.th/api/web/index.php'
  // b = 'http://localhost/siam-exchange-api/web/index.php'
  // b = 'https://infiltech.org/checkphra-api/web/index.php/v1/'
  // b = 'http://192.168.1.40/siam-exchange-api/web/index.php'

}

const create = (baseURL = b) => {

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
    },
    timeout: 10000
  })

  //รวม function Api
  const getCurrency = () => api.get('', { r: 'v1/currency/list' })
  const getAboutUs = () => api.get('', { r: 'v1/aboutus/index' })
  const getContact = () => api.get('', { r: 'v1/contact/index' })
  const getBanner = () => api.get('', { r: 'v1/banner/index' })
  const getMenu = () => api.get('', { r: 'v1/menu/index' })
  const getNews = () => api.get('', { r: 'v1/news/index' })

  return {
    getCurrency,
    getAboutUs,
    getContact,
    getBanner,
    getMenu,
    getNews
  }
}

// let's return back our create method as the default.
export default {
  create
}
