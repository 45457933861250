import { call, put } from 'redux-saga/effects'
import CurrencyActions from '../Redux/CurrencyRedux'

export function* requestCurrency(api) {
  const response = yield call(api.getCurrency)

  console.log(response)

  if (response.ok) {
    yield put(CurrencyActions.requestCurrencySuccess(response.data))
  }
}
