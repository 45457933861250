import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    requestCurrency: null,
    requestCurrencySuccess: ['data'],

    textCountryName : ['country']
})

export const CurrencyTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  currency : '',
  data: [],

  country: [],
})

/* ------------- Reducers ------------- */

export const success = (state, { data }) => {
    return state.merge({ data: data })
}

export const countrytext = (state, { country }) => {
  return state.merge({ country: country })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.REQUEST_CURRENCY_SUCCESS]: success,
  [Types.TEXT_COUNTRY_NAME]: countrytext
  
})