import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

let list1 = []
let list2 = []

class Preview extends Component {

  componentDidMount() {
    if (this.props.list) {
      let a = this.props.list.length / 2

    }

  }

  // _renderList = (list) => {
  //   console.log(list)
  //   return (
  //     list.map((e, i) => {
  //       return (
  //         <View style={{ height: 30 }}><Text>{e.country_name}</Text></View>
  //       )
  //     })
  //   )
  // list.map((x, i) => {
  //   console.log(x)
  //   return (
  //     <View style={{ height: 30 }}><Text>{x.country_name}</Text></View>
  //   )
  // }
  // )
  // }

  render() {
    list1 = []
    list2 = []
    console.log(this.props.list)
    let a = this.props.list.length
    let b = this.props.list.length / 2
    console.log(a)
    console.log(b)
    for (let i = 0; i < b; i++) {
      let name = JSON.parse(this.props.list[i].country_name)
      let currency = this.props.list[i].currency
      let buy = this.props.list[i].buying
      let selling = this.props.list[i].selling
      let img = this.props.list[i].icon_country
      // console.log(img)
      list1.push(<View style={{ height: 30, flexDirection: 'row', backgroundColor: (i % 2 == 0 ? "#EAE7E7" : "") }}>
        <View style={{
          flex: 0.35,
          paddingLeft: 20,
          justifyContent: "center"
        }}>
          <Text style={{ fontSize: 10.5 }}>{name.en}</Text>
        </View>
        <View style={{ flex: 0.35, justifyContent: 'center' }}><Text style={{ fontSize: 10.5 }}>{currency}</Text></View>
        <View style={{ flex: 0.25, justifyContent: 'center' }}><Text style={{ fontSize: 12, fontWeight: 'bold' }}>{buy}</Text></View>
        <View style={{ flex: 0.25, justifyContent: 'center', borderRightWidth: 1, borderRightColor: "grey" }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{selling}</Text></View>
      </View>)

    }
    for (let i = b; i < a; i++) {
      // list2.push(this.props.list[i])
      let name2 = JSON.parse(this.props.list[i].country_name)
      let currency = this.props.list[i].currency
      let buy = this.props.list[i].buying
      let selling = this.props.list[i].selling
      list2.push(<View style={{ height: 30, backgroundColor: (i % 2 == 1 ? "#EAE7E7" : ""), flexDirection: "row" }}>
        <View style={{ flex: 0.3, paddingLeft: 20, justifyContent: "center" }}><Text style={{ fontSize: 10.5 }}>{name2.en}</Text></View>
        <View style={{ flex: 0.35, justifyContent: 'center' }}><Text style={{ fontSize: 9.5 }}>{currency}</Text></View>
        <View style={{ flex: 0.15, justifyContent: 'center' }}><Text style={{ fontSize: 12, fontWeight: 'bold' }}>{buy}</Text></View>
        <View style={{ flex: 0.15, justifyContent: 'center' }}><Text style={{ fontSize: 12, fontWeight: 'bold' }}>{selling}</Text></View>
      </View>)
    }
    console.log(list1)
    console.log(list2)
    return (
      <Document>
        <Page size="A4" >
          <View style={{ height: 40, flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 0.5 }}>
              <View style={{ height: 50, paddingLeft: 10, justifyContent: 'center' }}>
                <Image
                  src={require("../assets/images/logo-dark.png")}
                  style={{ height: 40, width: 120 }}
                />
              </View>
            </View>
            <View style={{ flex: 0.5 }}>
              <View style={{ height: 50, justifyContent: "center", paddingLeft: 20 }}>
                <Text style={{ fontSize: 8.5 }}>Last Update :{" "}{this.props.date}{" / "}{this.props.time}</Text>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <View style={{ height: 30, flexDirection: 'row', borderRightColor: "grey", borderRightWidth: 1 }}>
                <View style={{ flex: 0.35, paddingLeft: 20 }}></View>
                <View style={{ flex: 0.35, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 10.5, fontWeight: "bold" }}>Currency</Text>
                </View>
                <View style={{ flex: 0.25, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 10.5, fontWeight: "bold" }}>Buying</Text>
                </View>
                <View style={{ flex: 0.25, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 10.5, fontWeight: "bold" }}>	Selling</Text>
                </View>
              </View>
              <View>
                {list1}
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ height: 30, flexDirection: 'row' }}>
                <View style={{ flex: 0.3, paddingLeft: 20 }}></View>
                <View style={{ flex: 0.35, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 10.5, fontWeight: "bold" }}>Currency</Text>
                </View>
                <View style={{ flex: 0.15, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 10.5, fontWeight: "bold" }}>Buying</Text>
                </View>
                <View style={{ flex: 0.15, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 10.5, fontWeight: "bold" }}>	Selling</Text>
                </View>
              </View>
              <View>
                {list2}
              </View>
            </View>
          </View>
        </Page>
      </Document >
    )
  }
}
// const mapStateToProps = (state) => ({
//     country: state.currency.country,
//     data: state.currency.data,
//     lang: state.language.language
// });

// const mapDispatchToProps = (dispatch) => ({
//     // getCurrency: () => dispatch(CurrencyActions.requestCurrency()),
// });
export default (Preview)