import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col } from 'reactstrap';

import BannerActions from '../Redux/BannerRedux'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../Layouts/css/banner.css'
class Banner extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.props.getImage()
    // this.props.data.map(e =>{this.props.set(e.content_aboutus)})
  }
  componentWillReceiveProps(newProps) {
    if (this.props.data != newProps.data) {
      console.log(newProps.data)
    }
  }
  findUrl = (e) => {
    return "http://siamexchange.co.th/".concat(e.image_path)
  }

  render() {
    let items = this.props.data.map(e => {
      return {
        src: this.findUrl(e),
        altText: '',
        caption: '',
        header: ''
      }
    })
    return (

      <Carousel showArrows={false} infiniteLoop autoPlay showThumbs={false} stopOnHover={false} id="banner">
        {this.props.data.map((e, i) => {
          return <div key={i} className='banner'>
            <img src={this.findUrl(e)} />
          </div>
        })}

      </Carousel>

    )
  }
}

const mapStateToProps = (state) => ({
  data: state.banner.data,
  currency: state.currency.data

});

const mapDispatchToProps = (dispatch) => ({
  getImage: () => dispatch(BannerActions.requestBanner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner)

