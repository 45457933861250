import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux'
// import Dropdown from 'react-dropdown'
// import 'react-dropdown/style.css'
import MenuActions from '../Redux/MenuRedux'
import LanguageActions from '../Redux/LanguageRedux'
import './css/header.css'

import logo from '../assets/images/logo.png'
// import { firebaseConnect, isEmpty } from 'react-redux-firebase'

const INITIAL_VALUE = {
  value: 'User',
  label: 'User'
}

class Header extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  state = {
    profileValue: INITIAL_VALUE,
    isOpen: false
  }

  componentDidMount() {
    this.props.getMenu()
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.menu && newProps.menu) {
      console.log(newProps.menu)
    }
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }


  changeLanguage(e) {
    console.log(e)
    this.props.setLanguage(e)
  }

  toggleNavbar() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {

    var lang = this.props.lang
    let list = this.props.data ? this.props.data.filter(e => e.id != 3) : []

    return (

      <header className="navigation" >
        <div className="list-lang container">
          <ul>
            <button className="lang" onClick={() => this.changeLanguage('th')}>ไทย</button>
            <a style={{ marginLeft: 20, marginRight: 20 }}>|</a>
            <button className="lang" onClick={() => this.changeLanguage('en')}>ENG</button>
            <a style={{ marginLeft: 20, marginRight: 20 }}>|</a>
            <button className="lang" style={{ marginRight: 20 }} onClick={() => this.changeLanguage('zh')}>中文</button>
          </ul>
        </div>

        {/* <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler> */}

        <Navbar expand="lg" className="menu">
          <Container className='navbar-header'>
            {/* <div className="container navbar-header"> */}
            <NavbarBrand href="#">
              <img src={logo} className="logo" />
            </NavbarBrand>

            <NavbarToggler onClick={() => this.toggleNavbar()} className="navbar-toggle minus collapsed">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </NavbarToggler>

            <Collapse isOpen={this.state.isOpen} navbar id="navbar-collapse-minus">
              <Nav className="ml-auto" navbar>

                {list.map((e) => {

                  console.log(e.id + e.link)
                  let id = 'link-'.concat(e.link)
                  return <NavItem key={e.id + e.link}>
                    < NavLink >
                      <Link
                        id = {id}
                        activeClass="menu-active navlink"
                        to={e.link}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        { JSON.parse(e.name_menu)[lang]}
                      </Link>
                    </NavLink>
                  </NavItem>
                })
                }


              </Nav>
            </Collapse>
            {/* </div> */}
          </Container>
        </Navbar>

      </header >
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.menu.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getMenu: () => dispatch(MenuActions.requestMenu()),
  setLanguage: (lang) => dispatch(LanguageActions.changeLanguage(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header)
