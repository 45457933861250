import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
import CalculateActions from '../Redux/CalculateRedux'
import CurrencyActions from '../Redux/CurrencyRedux'

// import Box1 from './Box1'
// import Box2 from './Box2'

import { strictEqual } from 'assert';

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

import '../Layouts/css/calculate.css'
class Calculate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      img: 'uploads/icon_country/2f0e39764d69700646c25e379d3a6aa2.png',
      poiter: 'buying',
      param: 'case1',
      active: 'buy',
      value: 0,
      result: 0
    }
    this.changeToSelling = this.changeToSelling.bind(this)
    this.changeToBuying = this.changeToBuying.bind(this)
    this.setParam = this.setParam.bind(this)
    this.props.getCurrency()

  }

  componentWillReceiveProps(newProps) {
    if (this.props.data != newProps.data) {
      console.log(newProps.data)
    }
    if (this.props.lang != newProps.lang) {
      console.log(newProps.lang)
    }

    this.recalculate(this.state.value, newProps.rate, newProps.extype)

  }

  changeToSelling = () => {
    // this.setState({
    //   param: 'case2',
    //   poiter: 'selling',
    //   active: 'sell'
    // })
    this.props.changeType('selling')
  }

  changeToBuying = () => {
    // this.setState({
    //   param: 'case1',
    //   poiter: 'buying',
    //   active: 'buy'
    // })
    this.props.changeType('buying')
  }

  setParam = () => {
    if (this.state.param == 'case1') {
      this.setState({
        param: 'case2'
      })
    } else {
      this.setState({
        param: 'case1'
      })
    }
  }
  // renderSwitch = (param) => {
  //   switch (param) {
  //     case 'buying':
  //       return <Row >
  //         <Col lg='5' md='5' xs="5" sm="5">
  //           <p>from</p>
  //           <Box1 poiter={this.state.poiter} />
  //         </Col>

  //         <Col lg='5' md='5' xs="5" sm="5">
  //           <p>to</p>
  //           <Box2 poiter={this.state.poiter} />
  //         </Col>
  //       </Row>;
  //     default:
  //       return <Row>
  //         <Col lg='5' md='5' xs="5" sm="5">
  //           <p>from</p>
  //           <Box2 poiter={this.state.poiter} />
  //         </Col>

  //         <Col lg='5' md='5' xs="5" sm="5">
  //           <p>to</p>
  //           <Box1 poiter={this.state.poiter} />
  //         </Col>
  //       </Row>;
  //   }
  // }

  _onSelect = (e) => {
    var val = e.target.value
    var data = this.props.data.filter((json_data) => json_data.currency == val)
    console.log(data)
    var a = data[0].icon_country
    var find = this.findCurrency(val)
    // this.props.setCurrency(find)
    this.props.changeCurrency(find)
    this.setState({ img: a })
  }

  findCurrency = (e) => {
    if (e == 'USD $ 50-100') {
      return 'USD $ 1'
    } else if (e == 'EUR 100-500') {
      return 'EUR 5-50'
    } else if (e == 'GBP 50') {
      return 'GBP 5-20'
    } else if (e == 'SGD 1000') {
      return 'SGD 2-100'
    } else if (e == 'MYR 50-100') {
      return 'MYR 1-2'
    } else if (e == 'KRW 5000-50000') {
      return 'KRW 1000'
    } else {
      return e
    }
  }

  recalculate(value, rate, extype) {
    let result = 0
    if (extype === 'buying') {
      result = (value * rate).toFixed(2)
    } else {
      result = (value / rate).toFixed(2)
    }

    this.setState({ value, result })
  }

  _change = (e) => {
    var value = e.target.value
    var split = value.split('')
    var size = split.length
    if (split[size - 1] == 1 ||
      split[size - 1] == 2 ||
      split[size - 1] == 3 ||
      split[size - 1] == 4 ||
      split[size - 1] == 5 ||
      split[size - 1] == 6 ||
      split[size - 1] == 7 ||
      split[size - 1] == 8 ||
      split[size - 1] == 9 ||
      split[size - 1] == 0 ||
      split[size - 1] == "." ||
      split[size - 1] == null) {
      var newValue = this.cutToValue(value)
      this.recalculate(newValue, this.props.rate, this.props.extype)
    }
  }

  cutToValue(str) {
    var words = str.split(',');
    var newWord = ''
    for (let i = 0; i < words.length; i++) {
      newWord = newWord.concat(words[i])
    }
    return newWord
  }

  render() {
    let menu = this.props.menu && this.props.menu.length ?
      JSON.parse(this.props.menu[1]['name_menu'])[this.props.lang] : ''

    var check = ''
    var res = "http://siamexchange.co.th/".concat(this.state.img)
    let text = "url(".concat(res).concat(")").concat(' 0% 0% / contain no-repeat')
    console.log(text)
    let { value } = this.state
    const options = this.props.data.map((obj) => {
      var array = obj.currency.split(' ')

      if (check != array[0]) {
        check = array[0]
        return <option key={obj.currency} value={obj.currency} style={{ color: "black" }}>{check}</option>
      }
    });

    let selectStyle = {
      background: text,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      // textIndent: '20vm',
      // textAlignLast: 'center',
      // textAlign: 'center',
      // backgroundPosition: 'center',
      width: 180,
      marginTop: 9,
      paddingLeft: 75
    };

    // var menu = JSON.parse(this.props.menu[1]['name_menu'])


    return (
      <Container>
        <div className='calculate' id="calcurrency">
          <h2 style={{ fontSize: 30, marginBottom: 20 }}>{menu}</h2>
          <div>
            <Row>
              <Col lg='6' md='6' xs="6" sm="6" className="buybox">
                <button className={this.props.extype == 'buying' ? 'active buy' : 'buy'} onClick={this.changeToBuying}>Buying</button>
              </Col>
              <Col lg='6' md='6' xs="6" sm="6" className="sellbox">
                <button className={this.props.extype === 'selling' ? 'active sell' : 'sell'} onClick={this.changeToSelling} >Selling</button>
              </Col>
            </Row>
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {/* <img src={res} style={{ width: 40, height: 40, marginRight: 15 }} /> */}

            <div include="form-input-select()">

              <select style={selectStyle} className="select" onChange={(e) => { this._onSelect(e) }} >
                {/* <img src={res} style={{ width: 40, height: 40, marginRight: 15 }} /> */}
                {options}
              </select>
            </div>
            <span style={{ fontSize: 20, fontWeight: 'bold', margin: '0 20px' }}> => </span>
            <span className="bathValue">{this.props.rate}</span><span>&nbsp;THB</span>
          </div>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>

            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <NumberFormat
                thousandSeparator={true}
                onChange={this._change}
                style={{
                  cursor: this.props.poiter == "selling" ? "not-allowed" : "",
                  backgroundColor: this.props.poiter == "selling" ? "transparent" : "#eee",
                  color: this.props.poiter == "selling" ? "white" : "black",
                  outline: 'none'
                }}
                className="boxinput"
                onFocus={this.setStateOnfocus}
                disabled={this.props.poiter == "selling"} />

              <span style={{ backgroundColor: '#eee', height: 36, color: 'black', padding: '0 10px' }}>
                {this.props.extype === 'buying' ? this.props.currency.substring(0, 3) : 'THB'}
              </span>

            </div>
            <div style={{ flex: 1 }}>
              <span style={{ margin: '0 20px' }}>=</span>
              <span ><NumberFormat className="currenValue" value={this.state.result} displayType={'text'} thousandSeparator={true} /> {this.props.extype === 'buying' ? 'THB' : this.props.currency.substring(0, 3)}</span>
            </div>

          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  country: state.currency.country,

  extype: state.calculate.extype,
  currency: state.calculate.currency,
  rate: state.calculate.rate,

  data: state.currency.data,
  value: state.currency.value,
  menu: state.menu.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  changeType: (t) => dispatch(CalculateActions.changeType(t)),
  changeCurrency: (c) => dispatch(CalculateActions.changeCurrency(c)),
  // setCurrency: (text) => dispatch(CalculateActions.valueCurrency(text)),
  getCurrency: () => dispatch(CurrencyActions.requestCurrency()),
  setCalculateType: (text) => dispatch(CalculateActions.typeToCal(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(Calculate)

